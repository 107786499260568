export abstract class Repository<T extends IDBRecord> {
	private _idb: IDBDatabase;

	constructor(idb: IDBDatabase) {
		this._idb = idb;
	}

	public fetch(id: number): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			const request = this._store(StoreType.RO).get(id);
			request.onsuccess = () => resolve(this._toRecord(request.result));
			request.onerror = () => reject(request.error);
		});
	}

	public async fetchMany(ids: number[]): Promise<T[]> {
		const promises = ids.map((id) => this.fetch(id));
		return await Promise.all(promises);
	}

	public fetchAll(): Promise<T[]> {
		return new Promise<T[]>((resolve, reject) => {
			const request = this._store(StoreType.RO).getAll();
			request.onsuccess = () =>
				resolve(request.result.map((record) => this._toRecord(record)));
			request.onerror = () => reject(request.error);
		});
	}

	public async add(
		props: Omit<IDBCompatibleModel<T>, "id" | "savedAt">,
	): Promise<T> {
		const id = await new Promise<number>((resolve, reject) => {
			const request = this._store(StoreType.RW).add({
				...props,
				savedAt: new Date().toISOString(),
			});

			request.onsuccess = () => resolve(request.result as number);
			request.onerror = () => reject(request.error);
		});

		return await this.fetch(id);
	}

	public async update(record: IDBCompatibleModel<T>): Promise<T> {
		const id = await new Promise<number>((resolve, reject) => {
			const request = this._store(StoreType.RW).put(record);
			request.onsuccess = () => resolve(request.result as number);
			request.onerror = () => reject(request.error);
		});

		return await this.fetch(id);
	}

	public async delete(id: number) {
		return await new Promise<number>((resolve, reject) => {
			const request = this._store(StoreType.RW).delete(id);
			request.onsuccess = () => resolve(id);
			request.onerror = () => reject(request.error);
		});
	}

	protected _store(storeType: StoreType): IDBObjectStore {
		return this._idb.transaction(this._name(), storeType).objectStore(
			this._name(),
		);
	}

	protected abstract _name(): string;

	protected abstract _toRecord(data: IDBCompatibleModel<T>): T;

	protected abstract _toDb(record: T): IDBCompatibleModel<T>;
}

export abstract class IDBRecord {
	public id: number;
	public savedAt: Date;

	constructor(id: number, savedAt: Date) {
		this.id = id;
		this.savedAt = savedAt;
	}
}

export interface BaseProps {
	id?: number;
	savedAt?: Date;
}

type IDBCompatible =
	| string
	| number
	| boolean
	| null
	| Date
	| ArrayBuffer
	| Blob
	| IDBCompatibleArray
	| IDBCompatibleObject;

interface IDBCompatibleArray extends Array<IDBCompatible> {}
interface IDBCompatibleObject {
	[key: string]: IDBCompatible;
}

// TODO: this is bad don't do this, cba fixing atm
export type IDBCompatibleModel<T> = {
	[K in keyof OmitMethods<T>]: T[K] extends IDBCompatible ? T[K]
		: IDBCompatible;
};

type OmitMethods<T> = {
	[K in keyof T as T[K] extends Function ? never : K]: T[K];
};

export enum StoreType {
	RW = "readwrite",
	RO = "readonly",
}
