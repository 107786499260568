import {
	Settings,
	strToSummModel,
	SummarizationModel,
} from "../utils/settings.ts";

const FIELD_HTML = `
	<div class="settings-field">
		<div class="settings-field-title"></div>
	</div>
`;

export class SettingsView {
	public static _instance: SettingsView;
	public _el: HTMLDivElement;

	private constructor(el: HTMLDivElement) {
		this._el = el;
	}

	public static instance(): SettingsView {
		if (this._instance) return this._instance;

		this._instance = new SettingsView(this._element());
		return this._instance;
	}

	public static toggle() {
		this.instance()._el.classList.toggle("hidden");
	}

	private static _element(): HTMLDivElement {
		let el = document.querySelector<HTMLDivElement>("#settings");
		if (el) return el!;

		el = document.createElement("div");
		el.id = "settings";
		el.classList.add("hidden");

		const wrapperEl = document.createElement("div");
		wrapperEl.id = "settings-wrapper";
		el.appendChild(wrapperEl);

		const notifEl = createNotifElement(wrapperEl);

		const selectSummEl = createSelectField(wrapperEl);
		const inputOpenAiKeyEl = createInputField(wrapperEl);
		const buttonSaveEl = createSaveButton(wrapperEl);

		document.body.append(el);

		buttonSaveEl.addEventListener("click", () => {
			const summModel = selectSummEl.value;
			const openAiKey = inputOpenAiKeyEl.value;
			this._saveSettings(summModel, openAiKey, notifEl);
		});

		return el;
	}

	private static _saveSettings(
		summModel: string,
		openAiKey: string,
		notifEl: HTMLDivElement,
	) {
		const model = strToSummModel(summModel);
		if (model == SummarizationModel.None) {
			Settings.instance().save({
				summarizationModel: model,
				openApiKey: openAiKey,
			});
			notifEl.textContent = "";
			this.toggle();
		} else if (!openAiKey) {
			notifEl.textContent =
				"OpenAI Api Key has to be set when using OpenAI summarization";
		} else {
			Settings.instance().save({
				summarizationModel: model,
				openApiKey: openAiKey,
			});
			notifEl.textContent = "";
			this.toggle();
		}
	}
}

function createNotifElement(parent: HTMLDivElement): HTMLDivElement {
	const el = document.createElement("div");
	el.id = "settings-notification";

	parent.appendChild(el);
	return el;
}

function createSelectField(parent: HTMLDivElement): HTMLSelectElement {
	const el = document.createElement("div");
	el.classList.add("settings-field");
	const titleEl = document.createElement("div");
	titleEl.classList.add("settings-field-title");
	titleEl.textContent = "Summarization Model";
	el.appendChild(titleEl);
	const selectEl = document.createElement("select");

	const options = [
		{ value: SummarizationModel.None, text: "None" },
		{ value: SummarizationModel.OpenAI, text: "OpenAI" },
	];

	options.forEach((optionData) => {
		const option = document.createElement("option");
		option.value = optionData.value;
		option.textContent = optionData.text;
		selectEl.appendChild(option);
	});

	el.appendChild(selectEl);
	parent.appendChild(el);
	return selectEl;
}

function createInputField(parent: HTMLDivElement): HTMLInputElement {
	const el = document.createElement("div");
	el.classList.add("settings-field");
	const titleEl = document.createElement("div");
	titleEl.classList.add("settings-field-title");
	titleEl.textContent = "OpenAI Api Key";
	el.appendChild(titleEl);
	const inputEl = document.createElement("input");
	inputEl.type = "text";
	inputEl.id = "openAiApiKey";
	el.appendChild(inputEl);

	parent.appendChild(el);
	return inputEl;
}

function createSaveButton(parent: HTMLDivElement): HTMLButtonElement {
	const el = document.createElement("div");
	el.id = "settings-actions";

	const saveBtnEl = document.createElement("button");
	saveBtnEl.id = "settings-actions-save";
	saveBtnEl.textContent = "Save";
	el.appendChild(saveBtnEl);

	parent.appendChild(el);
	return saveBtnEl;
}

//          |   [Y]
//          |    |
// [Clear]  |  [X]

const CLEAR_ARTICLES_BUTTON_INNER_HTML = `
	<div id="settings-clear-articles-initial" class="button-confirmation-initial">
		Clear Articles
	</div>
	<div id="settings-clear-articles-no" class="button-confirmation-no hidden">
		Cancel
	</div>
	<div id="settings-clear-articles-yes" class="button-confirmation-yes hidden">
		Accept
	</div>
`;

function createClearArticlesButton(parent: HTMLDivElement) {
	const el = document.createElement("div");
	el.id = "settings-clear-articles";
	el.classList.add("button-confirmation");

	el.innerHTML = CLEAR_ARTICLES_BUTTON_INNER_HTML;

	parent.appendChild(el);
	return el;
}
