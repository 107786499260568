import { Article } from "../repositories/articles_repository.ts";
import { elSetText, formatDate } from "../utils/shared.ts";

const ELEMENT = `
  <div id="article-view-close">
		<img id="article-view-close-button" src="assets/close.svg" />
	</div>
	<div id="article-view-wrapper">
    <div id="article-view-title"></div>
    <div id="article-view-link"></div>
    <div id="article-view-date"></div>
    <div id="article-view-content"></div>
	</div>
`;

export class ArticleView {
	private static _instance: ArticleView;
	private _el: HTMLDivElement;

	private constructor(el: HTMLDivElement) {
		this._el = el;
	}

	public static instance() {
		if (this._instance) return this._instance;

		const el = element();
		document.body.append(el);

		const closeEl = el.querySelector<HTMLDivElement>("#article-view-close")!;
		closeEl.addEventListener("click", () => this._close(el));

		this._instance = new ArticleView(el);
		return this._instance;
	}

	private static _close(el: HTMLDivElement) {
		el.classList.add("hidden");
	}

	public show(article: Article) {
		const wrapperEl = this._el.querySelector<HTMLDivElement>(
			"#article-view-wrapper",
		)!;
		elSetText(wrapperEl, "#article-view-title", article.title);
		elSetText(wrapperEl, "#article-view-link", article.link);
		elSetText(wrapperEl, "#article-view-date", formatDate(article.savedAt));
		elSetText(wrapperEl, "#article-view-content", article.text);

		if (this._el.classList.contains("hidden")) {
			this._el.classList.remove("hidden");
		}
	}
}

function element(): HTMLDivElement {
	let el = document.querySelector<HTMLDivElement>("#article-view");
	if (!el) {
		el = document.createElement("div");
		el.id = "article-view";
		el.classList.add("hidden");
		el.innerHTML = ELEMENT;
	}
	return el;
}
