import { Db } from "../db.ts";
import { Article } from "../repositories/articles_repository.ts";
import type { Summary } from "../repositories/summaries_repository.ts";
import { Settings, SummarizationModel } from "../utils/settings.ts";

export async function saveSummary(article: Article): Promise<Summary> {
	const summary = await summarize(article.text);
	const db = await Db.instance();

	return await db.summaries().add({
		title: article.title,
		content: summary,
		articleId: article.id,
	});
}

async function summarize(content: string): Promise<string> {
	if (content.length <= 200) return content;

	const apiKey = Settings.instance().summarizationKey();
	const summModel = Settings.instance().summarizationModel();
	if (!apiKey || summModel == SummarizationModel.None) return content;

	const apiUrl = "https://api.openai.com/v1/chat/completions";

	try {
		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${apiKey}`,
			},
			body: JSON.stringify({
				model: "gpt-3.5-turbo",
				messages: [
					{
						role: "system",
						content: "Summarize the article in 200 words or less",
					},
					{ role: "user", content: content },
				],
			}),
		});

		if (!response.ok) {
			throw new Error(`API error: ${response.statusText}`);
		}

		const data = await response.json();
		const summary = data.choices[0].message.content;
		return summary;
	} catch (error) {
		console.error("Error fetching summary:", error);
		throw error;
	}
}
