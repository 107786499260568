import { fetchGdelt } from "../gdelt.ts";
import { openShared } from "../utils/shared.ts";
import { ArticleList } from "./article-list.ts";
import { Loader } from "./loader.ts";
import { SettingsView } from "./settings-view.ts";

const ELEMENT = `
  <div class="search-horizontal-buttons-wrapper">
		<div id="search-fab-settings">
			<img id="search-fab-settings-image" src="assets/settings.svg" />
		</div>
    <div id="search-fab-import">
      <img id="search-fab-import-image" src="assets/import.svg" />
    </div>
 </div>
  <div class="search-horizontal-buttons-wrapper">
    <div id="search-gdelt-button" class="hidden">
      <img id="search-gdelt-button-image" src="assets/web.svg" />
    </div>
     <div id="search-fab-button">
      <img id="search-fab-button-image" src="assets/search.svg" />
    </div>
  </div>
`;

export class Fab {
	private static _instance: Fab;
	private el: HTMLDivElement;
	private searchBtn: HTMLDivElement;
	private gdeltBtn: HTMLDivElement;
	private importBtn: HTMLDivElement;
	private settingsBtn: HTMLDivElement;

	private constructor(
		el: HTMLDivElement,
		searchBtn: HTMLDivElement,
		gdeltBtn: HTMLDivElement,
		importBtn: HTMLDivElement,
		settingsBtn: HTMLDivElement,
	) {
		this.el = el;
		this.searchBtn = searchBtn;
		this.gdeltBtn = gdeltBtn;
		this.importBtn = importBtn;
		this.settingsBtn = settingsBtn;
	}

	public static get instance(): Fab {
		if (this._instance) return this._instance;

		const el = element();
		const searchBtn = el.querySelector<HTMLDivElement>("#search-fab-button")!;
		const gdeltBtn = el.querySelector<HTMLDivElement>("#search-gdelt-button")!;
		const importBtn = el.querySelector<HTMLDivElement>("#search-fab-import")!;
		const settingsBtn = el.querySelector<HTMLDivElement>("#search-fab-settings")!;

		const fab = new Fab(el, searchBtn, gdeltBtn, importBtn, settingsBtn);
		fab.searchBtn.addEventListener("click", () => {
			fab.toggle();
		});
		fab.importBtn.addEventListener("click", async () => {
			try {
				const text = await navigator.clipboard.readText();
				openShared(text);
			} catch (error) {
				console.error("Failed to read clipboard contents:", error);
			}
		});
		fab.gdeltBtn.addEventListener("click", async () => {
			const searchInput = document.querySelector<HTMLInputElement>(
				"#search-input",
			)!;
			Loader.instance.show();
			const results = await fetchGdelt(searchInput.value!);
			ArticleList.instance.render(results);
			fab.toggle();
			Loader.instance.hide();
		});
		fab.settingsBtn.addEventListener("click", () => {
			SettingsView.toggle();
			fab.toggle();
		});

		this._instance = fab;
		return this._instance;
	}

	public toggle() {
		this.gdeltBtn.classList.toggle("hidden");
	}
}

function element(): HTMLDivElement {
	let el = document.querySelector<HTMLDivElement>("#search-fab");
	if (!el) {
		el = document.createElement("div");
		el.id = "search-fab";
		el.innerHTML = ELEMENT;
		document.body.append(el);
	}
	return el;
}
