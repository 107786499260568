import {
	BaseProps,
	IDBCompatibleModel,
	IDBRecord,
	Repository,
  StoreType,
} from "./repository.ts";

export class Article extends IDBRecord {
	public title: string;
	public text: string;
	public link: string;
	public publishedAt: Date | null;

	constructor(
		id: number,
		title: string,
		text: string,
		link: string,
		publishedAt: Date | null,
		savedAt: Date,
	) {
		super(id, savedAt);
		this.title = title;
		this.text = text;
		this.link = link;
		this.publishedAt = publishedAt;
	}

	source(): ArticleSource {
		if (
			this.link.startsWith("https://twitter.com") ||
			this.link.startsWith("https://x.com")
		) {
			return ArticleSource.Twitter;
		}

		return ArticleSource.Other;
	}
}

export enum ArticleSource {
	Instagram,
	Twitter,
	Other,
}

export interface ArticleProps extends BaseProps {
	title: string;
	text: string;
	link: string;
	publishedAt: Date;
}

export class ArticlesRepository extends Repository<Article> {
	private static store_name = "articles";

	public static migrate(idb: IDBDatabase) {
		// V1
		if (!idb.objectStoreNames.contains(this.store_name)) {
			const store = idb.createObjectStore(this.store_name, {
				keyPath: "id",
				autoIncrement: true,
			});

			store.createIndex("unique_link", "link", { unique: true });
		}
	}

	protected _name(): string {
		return "articles";
	}

	protected _toRecord(data: IDBCompatibleModel<Article>): Article {
		return new Article(
			data.id,
			data.title,
			data.text,
			data.link,
			data.publishedAt ? new Date(data.publishedAt) : null,
			new Date(data.savedAt),
		);
	}

	protected _toDb(record: Article): IDBCompatibleModel<Article> {
		return {
			id: record.id,
			title: record.title,
			text: record.text,
			link: record.link,
			publishedAt: record.publishedAt,
			savedAt: new Date(),
		};
	}

	public fetchLast(amount: number = 10): Promise<Article[]> {
		return new Promise((resolve, reject) => {
			const items: Article[] = [];
			const request = this._store(StoreType.RO).openCursor(null, "prev");

			request.onsuccess = (event) => {
				const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
				if (cursor && items.length < amount) {
					items.push(this._toRecord(cursor.value));
					cursor.continue();
				} else {
					resolve(items);
				}
			};

			request.onerror = () => {
				reject(new Error("Failed to retrieve items from the store"));
			};
		});
	}
}
