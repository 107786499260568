import { Article } from "./repositories/articles_repository.ts";

export async function fetchGdelt(query: string): Promise<Article[]> {
	const url = `https://api.gdeltproject.org/api/v2/doc/doc?query="${
		encodeURI(query)
	}"+sourcelang:english&format=json`;

	const response = await fetch(url);
	if (!response.ok) {
		throw new Error(`Failed to fetch GDELT articles: ${response.statusText}`);
	}

	const list: GdeltResponse = await response.json();
	return list.articles?.map((article: GdeltArticle) => {
		return new Article(
			0,
			article.title,
			"",
			article.url,
			gdeltDateToDate(article.seendate),
			new Date(),
		);
	}) || [];
}

interface GdeltArticle {
	title: string;
	url: string;
	source: string;
	seendate: string;
	language: string;
}

interface GdeltResponse {
	articles: GdeltArticle[];
	query: string;
	totalResults: number;
}

function gdeltDateToDate(dateString: string): Date {
	//20240906T211500Z
	const year = parseInt(dateString.slice(0, 4), 10);
	const month = parseInt(dateString.slice(4, 6), 10) - 1;
	const day = parseInt(dateString.slice(6, 8), 10);
	const hours = parseInt(dateString.slice(9, 11), 10);
	const minutes = parseInt(dateString.slice(11, 13), 10);
	const seconds = parseInt(dateString.slice(13, 15), 10);

	return new Date(year, month, day, hours, minutes, seconds);
}
