export function elSetText(
	parent: HTMLDivElement,
	childId: string,
	value: string,
): HTMLDivElement {
	const el = parent.querySelector<HTMLDivElement>(childId)!;
	el.textContent = value;
	return el;
}

export function openShared(url: string) {
	const urlObj = new URL(url);
	urlObj.searchParams.set("shlock_search", "1");
	globalThis.window.open(urlObj.toString(), "_blank");
}

export function formatDate(date: Date | null): string {
	if (!(date instanceof Date)) return "---";

	return date.toLocaleDateString("en-GB", {
		day: "numeric",
		month: "short",
		year: "numeric",
	});
}
