import { BaseProps, IDBRecord, Repository, IDBCompatibleModel } from "./repository.ts";

export class Summary extends IDBRecord {
	public title: string;
	public content: string;
	public articleId: number;

	constructor(
		id: number,
		title: string,
		content: string,
		articleId: number,
		savedAt: Date,
	) {
		super(id, savedAt);
		this.title = title;
		this.content = content;
		this.articleId = articleId;
	}
}

export interface SummaryProps extends BaseProps {
	title: string;
	content: string;
	articleId: number;
}

export class SummariesRepository extends Repository<Summary> {
	private static store_name = "summaries";

	public static migrate(idb: IDBDatabase) {
		// V1
		if (!idb.objectStoreNames.contains(this.store_name)) {
			const store = idb.createObjectStore(this.store_name, {
				keyPath: "id",
				autoIncrement: true,
			});

			store.createIndex("unique_articleId", "articleId", { unique: true });
		}
	}

	protected _name(): string {
		return "summaries";
	}

	protected _toRecord(data: IDBCompatibleModel<Summary>): Summary {
		return new Summary(
			data.id,
			data.title,
			data.content,
			data.articleId,
			data.savedAt,
		);
	}

	protected _toDb(record: Summary): IDBCompatibleModel<Summary> {
		return {
			id: record.id,
			title: record.title,
			content: record.content,
			articleId: record.articleId,
			savedAt: record.savedAt,
		};
	}
}
