import { ArticlesRepository } from "./repositories/articles_repository.ts";
import { SummariesRepository } from "./repositories/summaries_repository.ts";

export class Db {
	private static _instance: Db;
	private idb: IDBDatabase;
	private articlesRepository: ArticlesRepository;
	private summariesRepository: SummariesRepository;

	private constructor(
		idb: IDBDatabase,
		articlesRepo: ArticlesRepository,
		summariesRepo: SummariesRepository,
	) {
		this.idb = idb;
		this.articlesRepository = articlesRepo;
		this.summariesRepository = summariesRepo;
	}

	public static instance(): Promise<Db> {
		return new Promise((resolve, reject) => {
			if (this._instance) resolve(this._instance);

			const request = indexedDB.open("shlock", 1);

			request.onupgradeneeded = () => {
				const db = request.result;
				ArticlesRepository.migrate(db);
				SummariesRepository.migrate(db);
			};

			request.onsuccess = () => {
				this._instance = new Db(
					request.result,
					new ArticlesRepository(request.result),
					new SummariesRepository(request.result),
				);
				resolve(this._instance);
			};

			request.onerror = () => {
				console.error("IndexedDb init error:", request.error);
				reject(request.error);
			};
		});
	}

	public articles(): ArticlesRepository {
		return this.articlesRepository;
	}

	public summaries(): SummariesRepository {
		return this.summariesRepository;
	}
}
