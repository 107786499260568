import { ArticleList } from "./components/article-list.ts";
import { Loader } from "./components/loader.ts";
import { Db } from "./db.ts";
import Fuse from "https://esm.sh/fuse.js@7.0.0";
import { Flavor, notify } from "./components/notifications.ts";
import { Fab } from "./components/fab.ts";
import { openShared } from "./utils/shared.ts";
import { Article, ArticleProps } from "./repositories/articles_repository.ts";
import { saveSummary } from "./summarizers/main.ts";
import { Summary } from "./repositories/summaries_repository.ts";

run();

async function run() {
	setTimeout(() => {
		globalThis.window.postMessage({
			type: "STORE_SHLOCK_TAB_ID",
		}, "*");
	}, 200);

	const db = await Db.instance();
	const summaries = await db.summaries().fetchAll();
	const fuse = new Fuse(summaries, { keys: ["title", "content"] });

	globalThis.window.addEventListener("message", async (event) => {
		console.log("Message from extension:", event.data);

		if (event.data.type == "SHLOCK_TAB_ID_SAVED") {
			// TODO: wait like a second and if this isn't hit, show link to extension
			console.log("tab id saved - comms to extension established");
		} else if (
			event.data.type == "SAVE_TWEET_DATA" ||
			event.data.type == "SAVE_ARTICLE_DATA"
		) {
			const props = event.data.data.data as ArticleProps;
			const article = await saveArticle(props);
			const summary = await saveSummary(article);
			fuse.add(summary);
		}
	});

	handleShare();
	await loadInitial();
	Fab.instance;

	const searchInput = document.querySelector<HTMLInputElement>(
		"#search-input",
	)!;

	searchInput.addEventListener("keydown", async (event: KeyboardEvent) => {
		if (event.key == "Enter") {
			Loader.instance.show();
			await search(fuse, searchInput.value);
			Fab.instance.toggle();
			Loader.instance.hide();
		}
	});
}

async function loadInitial() {
	const db = await Db.instance();
	const articles = await db.articles().fetchLast(10);
	ArticleList.instance.render(articles);
}

async function search(fuse: Fuse<Summary>, query: string) {
	const summaries = fuse.search(query);
	const db = await Db.instance();
	const articles = await db.articles().fetchMany(
		summaries.map((summ) => summ.item.articleId),
	);
	ArticleList.instance.render(articles);
}

function handleShare() {
	const urlParams = new URLSearchParams(globalThis.window.location.search);
	const shareUrl = urlParams.get("share_url");

	if (shareUrl) {
		openShared(shareUrl!);
	}
}

async function saveArticle(props: ArticleProps): Promise<Article> {
	const db = await Db.instance();
	try {
		const record = await db.articles().add(props);
		notify("Article added", Flavor.Green);
		return record;
	} catch (e) {
		notify("Could not add article", Flavor.Red);
		throw e;
	}
}
