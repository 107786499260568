export class Loader {
	private static _instance: Loader;
	private el: HTMLDivElement;

	constructor(el: HTMLDivElement) {
		this.el = el;
	};

	public static get instance() {
		if (this._instance) return this._instance;

		const el = document.querySelector<HTMLDivElement>("#loader")!;

		this._instance = new Loader(el);

		return this._instance;
	}

	public show() {
		this.el.classList.remove("invisible");
	}

	public hide() {
		if (!this.el.classList.contains("invisible")) {
			this.el.classList.add("invisible");
		}
	}
}
