const STORAGE_KEY = "shlockSettings";

export class Settings {
	private static _instance: Settings;
	private _settings: SettingsObject;

	private constructor(settings: SettingsObject) {
		this._settings = settings;
	}

	public static instance(): Settings {
		if (this._instance) return this._instance;

		const settingsStr = localStorage.getItem(STORAGE_KEY);
		let settings: SettingsObject;
		if (settingsStr) {
			settings = JSON.parse(settingsStr);
		} else {
			settings = initialSettings();
		}
		this._instance = new Settings(settings);
		return this._instance;
	}

	public save(obj: SettingsObject) {
		const str = JSON.stringify(obj);
		localStorage.setItem(STORAGE_KEY, str);
		this._settings = obj;
	}

	public enableSummarization(): boolean {
		return this._settings.summarizationModel == SummarizationModel.OpenAI &&
			!!this._settings.openApiKey;
	}

	public summarizationKey(): string | null {
		return this._settings.openApiKey;
	}

	public summarizationModel(): SummarizationModel {
		return this._settings.summarizationModel;
	}
}

export enum SummarizationModel {
	OpenAI = "openAi",
	None = "none",
}

export function strToSummModel(input: string): SummarizationModel {
	switch (input) {
		case "openAi": {
			return SummarizationModel.OpenAI;
		}
		case "none": {
			return SummarizationModel.None;
		}
		default: {
			throw new Error(`invalid summarization model: ${input}`);
		}
	}
}

interface SettingsObject {
	openApiKey: string | null;
	summarizationModel: SummarizationModel;
}

function initialSettings(): SettingsObject {
	return {
		openApiKey: null,
		summarizationModel: SummarizationModel.None,
	};
}
