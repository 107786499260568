import { Article, ArticleSource } from "../repositories/articles_repository.ts";
import { elSetText, formatDate } from "../utils/shared.ts";
import { ArticleView } from "./view-article.ts";

export class ArticleList {
	private static _instance: ArticleList;
	private el: HTMLDivElement;

	private constructor(el: HTMLDivElement) {
		this.el = el;
	}

	public static get instance(): ArticleList {
		if (this._instance) return this._instance;

		const el = document.querySelector<HTMLDivElement>("#results")!;
		this._instance = new ArticleList(el);

		return this._instance;
	}

	public render(articles: Article[]) {
		while (this.el.firstChild) {
			this.el.removeChild(this.el.firstChild);
		}

		articles.forEach((article) => {
			this.el.append(fillArticleTemplate(article));
		});
	}
}

const ARTICLE_TEMPLATE_HTML = `
  <div class="result-title-wrapper">
    <div class="result-title"></div>
    <div class="result-source"></div>
  </div>
  <div class="result-meta-wrapper">
	  <div class="result-link">
      <a class="result-anchor"></a>
    </div>
    <div class="result-date"></div>
  </div>
`;

function fillArticleTemplate(article: Article): HTMLDivElement {
	const el = document.createElement("div");
	el.classList.add("result-template");
	el.innerHTML = ARTICLE_TEMPLATE_HTML;

	const titleEl = elSetText(el, ".result-title", article.title);
	titleEl.addEventListener("click", () => {
		ArticleView.instance().show(article);
	});
	elSetText(el, ".result-source", domain(article.link));
	elSetText(
		el,
		".result-date",
		`${formatDate(article.publishedAt)} | ${formatDate(article.savedAt)}`,
	);
	elArticleSetMeta(el, article.link, article.source());

	return el;
}

function elArticleSetMeta(
	parent: HTMLDivElement,
	link: string,
	source: ArticleSource,
) {
	const el = parent.querySelector<HTMLDivElement>(".result-link")!;
	const aEl = el.querySelector<HTMLAnchorElement>(".result-anchor")!;
	aEl.href = link;

	let src = "assets/external.svg";
	if (source == ArticleSource.Twitter) {
		src = "assets/twitter.svg";
	}

	const imEl = document.createElement("img") as HTMLImageElement;
	imEl.src = src;
	imEl.classList.add("result-anchor-image");
	aEl.append(imEl);
}

function domain(url: string): string {
	try {
		const parsedUrl = new URL(url);
		return parsedUrl.hostname;
	} catch (error) {
		return `${error}`;
	}
}
