import { elSetText } from "../utils/shared.ts";

const ELEMENT = `
  <div class="notification-icon">
    <img class="notification-svg" src="assets/success.svg" />
  </div>
  <div class="notification-text"></div>
`;

export enum Flavor {
	Green,
	Red,
	Yellow,
}

export function notify(message: string, flavor: Flavor) {
	const el = element();
	const notification = createNotification(message, flavor);
	el.appendChild(notification);
	setTimeout(() => {
		if (el.firstChild) el.removeChild(el.firstChild);
	}, 3500);
}

function createNotification(message: string, flavor: Flavor): HTMLDivElement {
	const el = document.createElement("div");
	el.classList.add("notification", flavorClass(flavor));
	el.innerHTML = ELEMENT;
	elSetText(el, ".notification-text", message);
	return el;
}

function flavorClass(flavor: Flavor): string {
	switch (flavor) {
		case Flavor.Red: {
			return "error";
		}
		case Flavor.Green: {
			return "success";
		}
		default: {
			return "warning";
		}
	}
}

function element(): HTMLDivElement {
	let el = document.querySelector<HTMLDivElement>("#notifications");
	if (!el) {
		el = document.createElement("div");
		el.id = "notifications";
		document.body.append(el);
	}
	return el;
}
